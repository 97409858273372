// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-daftar-layanan-sekarang-jsx": () => import("./../../../src/pages/daftar-layanan-sekarang.jsx" /* webpackChunkName: "component---src-pages-daftar-layanan-sekarang-jsx" */),
  "component---src-pages-form-signup-jsx": () => import("./../../../src/pages/form/signup.jsx" /* webpackChunkName: "component---src-pages-form-signup-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-joinpartnership-jsx": () => import("./../../../src/pages/joinpartnership.jsx" /* webpackChunkName: "component---src-pages-joinpartnership-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-promo-3-months-jsx": () => import("./../../../src/pages/promo-3-months.jsx" /* webpackChunkName: "component---src-pages-promo-3-months-jsx" */),
  "component---src-pages-term-of-use-jsx": () => import("./../../../src/pages/term-of-use.jsx" /* webpackChunkName: "component---src-pages-term-of-use-jsx" */),
  "component---src-pages-white-paper-jsx": () => import("./../../../src/pages/white-paper.jsx" /* webpackChunkName: "component---src-pages-white-paper-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

