module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chatbiz.id | Layanan Asisten Virtual Chatbot Indonesia","short_name":"Chatbiz.id","start_url":"/","background_color":"#fff","theme_color":"#04589b","display":"standalone","icon":"src/assets/img/android-icon-36x36.png","icons":[{"src":"/favico/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favico/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favico/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favico/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favico/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favico/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d6cea70bacb27449bc02c65ad68d5c78"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PX65BTM","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src1498575737/src/website/src/intl","languages":["en","id"],"defaultLanguage":"id","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
